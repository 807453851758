import BaseRequest from "@/request/baseRequest.js";
const http = new BaseRequest()

const headers = {
    'content-type': 'application/json'
}
export default {
    // 商铺查询
    queryShops(data) {
        return http.post('/buyer/shop/queryShops', data, headers)
    },
    queryProductsCommentStar(data) {
        return http.post(`/buyer/buy/products/queryProductsCommentStar`, data, { 'content-type': 'application/json' })
    },

    123(data) {
        return http.post('https://ag.tvrzca.com/agent/record/queryUserUnreadList', data, headers)
    },
    confirmReceipt(data) {
        return http.post(`/buyer/buy/confirmReceipt`, data, { 'content-type': 'application/json' })
    },
    collectCount(data) {
        return http.get(`buyer/collect/count`, data, headers)
    },

    addCart(data) {
        return http.post('/buyer/cart/add', data, headers)
    },

    queryChoiceOption(data) {
        return http.get('/buyer/products/queryChoiceOption', data, headers)
    },

    getCartList(data) {
        return http.post('/buyer/cart/list', data, headers)
    },
    deleteCart(data) {
        return http.post('/buyer/cart/remove', data, headers)
    },

    login(data) {
        return http.post('/buyer/user/login', data, headers)
    },
    //注册
    register(data) {
        return http.post('/buyer/user/register', data, headers)
    },

    //忘记密码
    forgetPassword(data) {
        return http.post(`/buyer/user/forgetPassword`, data, { 'content-type': 'application/json' })
    },

    //获取用户信息
    getUserInfo(data) {
        return http.post(`/buyer/user/getUserInfo`, data)
    },

    // 获取验证码手机
    getPhoneCode(data) {
        return http.post('/buyer/user/getPhoneCode', data, headers)
    },

    // 获取验证码邮箱
    getEmailCode(data) {
        return http.post('/buyer/user/getEmailCode', data, headers)
    },

    //轮播图
    getBanner(data) {
        return http.get('/buyer/banner/getBanner', data, headers)
    },
    //获取品牌
    getBrand(data) {
        return http.get('/buyer/brand/getBrand', data, headers)
    },

    //获取商品分类
    getCategory(data) {
        return http.get('/buyer/category/getCategory', data)
    },
    //获取创建店铺的分类
    getShopCategory(data) {
        return http.get('/buyer/shopCategory/getShopCategory', data)
    },
    queryProductsInAdmin(data) {
        return http.post('/buyer/products/queryProductsInAdmin', data, headers)
    },

    //主页查询产品
    homeQueryProducts(data) {
        return http.post('/buyer/products/homeQueryProducts', data, headers)
    },
    //首页搜索展示店铺
    homeQueryShop(data) {
        return http.post('/buyer/shop/homeQueryShop', data, headers)
    },

    //商品详情
    queryUpProductTime(data) {
        return http.post('/buyer/products/queryUpProductTime', data, { 'content-type': 'application/json' })
    },
    //查询单个店铺
    querySingleShop(data) {
        return http.post('/buyer/shop/queryShop', data, headers)
    },
    //查询店铺产品
    queryProductsByShops(data) {
        return http.post('/buyer/shop/queryProductsByShops', data, headers)
    },
    //查看精选分类
    getHandpickCategory(data) {
        return http.get('/buyer/category/getHandpickCategory', data, headers)
    },

    //客服链接
    getLiveChat(data) {
        return http.get(`/buyer/liveChat/getLiveChat`, data)
    },

    //获取地址集
    getAddress(data) {
        return http.get(`/buyer/banner/getAddress`, data)
    },

    //删除地址
    delAddress(data) {
        return http.post(`/buyer/banner/delAddress`, data, headers)
    },

    //添加地址
    addAddress(data) {
        return http.post(`/buyer/banner/addAddress`, data, headers)
    },
    //添加地址
    updateAddress(data) {
        return http.post(`buyer/banner/updateAddress`, data, headers)
    },
    //上传文件
    getUploadFile(data, headers) {
        console.log(headers)
        return http.post(`/buyer/file/uploadFile`, data, headers)

        // return axios.post(`/buyer/file/uploadFile`, data,{'content-type':'application/json'})

    },

    //修改密码
    updatePassword(data) {
        return http.post(`/buyer/user/updatePassword`, data, { 'content-type': 'application/json' })
    },

    //下单
    getBuyProducts(data) {
        return http.post(`/buyer/buy/buyProducts`, data, { 'content-type': 'application/json' })
    },

    //查看订单
    queryOrderHistory(data) {
        return http.post(`/buyer/buy/queryOrder`, data, { 'content-type': 'application/json' })
    },

    //退货 取消订单
    returnOrder(data) {
        return http.post(`/buyer/buy/returnOrder`, data, { 'content-type': 'application/json' })
    },

    //确认收货
    confirmOrder(data) {
        return http.post(`/buyer/buy/confirmOrder`, data, { 'content-type': 'application/json' })
    },

    //查看订单详情
    queryOrderDetail(data) {
        return http.post(`/buyer/buy/queryOrderDetail`, data, { 'content-type': 'application/json' })
    },

    // 巴西支付开启关闭
    getBrazilianPaymentState(data) {
        return http.get(`/buyer/user/getIsThirdpartyPayment`, data)
    },

    //是否开启第三方支付
    getIsThirdpartyPayment(data) {
        return http.get(`/buyer/user/getIsThirdpartyPayment`, data)
    },

    //获取支付渠道
    getPayChannel(data) {
        return http.get(`/buyer/pay/getPayChannel`, data)
    },

    //巴西支付 充值
    brazilRecharge(data) {
        return http.post(`/buyer/pay/recharge/thirdparty/brazil`, data, { 'content-type': 'application/json' })
    },

    //充值接口
    currencyRecharge(data) {
        return http.post(`/buyer/pay/recharge`, data, { 'content-type': 'application/json' })
    },

    //提现接口
    currencyWithdraw(data) {
        return http.post(`/buyer/pay/withdraw`, data, { 'content-type': 'application/json' })
    },

    //获取提现汇率
    getWithdrawFee(data) {
        return http.get(`/buyer/wFee/getWfee`, data)
    },

    //上传文件
    uploadFile(data) {
        return http.post(`/buyer/file/uploadFile`, data)
    },

    //提现配置地址查询
    queryUserWallet(data) {
        return http.get(`/buyer/userWallet/queryUserWallet`, data)
    },

    //编辑提现地址修改
    editUserWallet(data) {
        return http.post(`/buyer/userWallet/editUserWallet`, data, { 'content-type': 'application/json' })
    },

    //删除钱包提现的配置
    delUserWallet(data) {
        return http.post(`/buyer/userWallet/delUserWallet`, data, { 'content-type': 'application/json' })
    },

    //用户添加钱包地址
    addUserWallet(data) {
        return http.post(`/buyer/userWallet/addUserWallet`, data, { 'content-type': 'application/json' })
    },

    //创建 卖家店铺  （新版暂时未使用: 此接口是 未有买家账户 直接创建卖家店铺的接口）
    createSellerShop(data) {
        return http.post(`/buyer/shop/createShop`, data, { 'content-type': 'application/json' })
    },

    //成为卖家 (开店  已有买家账户  --->  卖家账户开店)
    becomeSeller(data) {
        return http.post(`/buyer/shop/changeShop`, data, { 'content-type': 'application/json' })
    },

    //通过推广创建店铺 成为卖家
    promoteCreateShop(data) {
        return http.post(`/buyer/shop/createShop`, data, { 'content-type': 'application/json' })
    },

    //获取域名
    getDomainLink(data) {
        return http.get(`/buyer/host/list`, data)
    },

    //主页查询畅销产品
    activeDemandProducts(data) {
        return http.post(`/buyer/products/homeQueryActiveDemandProducts`, data, { 'content-type': 'application/json' })
    },
    //主页查询每日上新产品
    newProducts(data) {
        return http.post(`/buyer/products/homeQueryNewProducts`, data, { 'content-type': 'application/json' })
    },

    //地址list 设置默认地址
    setDefaultAddress(data) {
        return http.post(`/buyer/banner/defaultAddressSetting`, data, { 'content-type': 'application/json' })
    },

    // 是否开启验证码 (开店 注册 修改密码等验证码是否开启控制)
    getIsEnableCode(data) {
        return http.get(`/buyer/user/getIsEnableCode`, data)
    },
    // 是否隐藏证件上传 （开店是否隐藏证件上传控制）
    getIsCertificate(data) {
        return http.get(`/buyer/user/getIsCertificate`, data)
    },

    //获取平台 logo name   (买家用户头像也是使用 平台logo)
    getPlatformInfo(data) {
        return http.get(`/buyer/logo/info`, data)
    },
    //下单接口
    buyProducts(data) {
        return http.post(`/buyer/buy/buyProducts`, data, { 'content-type': 'application/json' })
    },
    //查询店长推荐商品
    queryShopRecommendsProducts(data) {
        return http.get(`/buyer/pcshop/queryShopRecommendsProducts`, data)
    },
    //查询最新上架商品
    queryShopNewProducts(data) {
        return http.get(`/buyer/pcshop/queryShopNewProducts`, data)
    },
    //店内查询所有商品：（sortField：最新上架 create_time , 热销商品 sales_volume）
    queryShopAllProducts(data) {
        return http.get(`/buyer/pcshop/queryShopAllProducts`, data)
    },

    //店铺分类接口
    queryShopCategory(data) {
        return http.get(`/buyer/pcshop/queryShopCategory`, data)
    },

    //最近交易记录 （包含充提）
    queryRecentTranRecord(data) {
        return http.post(`/buyer/trans/queryRecentTranRecord`, data, { 'content-type': 'application/json' })
    },

    // 系统配置接口，by key
    getHashMapByKey(data) {
        return http.get(`/buyer/mallHashmap/getHashMapByKey`, data);
    },

    //查询账单 账变明细
    queryBillData(data) {
        return http.post(`/buyer/mallBill/queryBill`, data, { 'content-type': 'application/json' })
    },

    //提现记录
    getWithdrawList(data) {
        return http.post(`/buyer/pay/withdrawList`, data, { 'content-type': 'application/json' })
    },

    //充值记录
    getRechargeList(data) {
        return http.post(`/buyer/pay/rechargeList`, data, { 'content-type': 'application/json' })
    },
    //三方充值订单
    getThreeRechargeList(data) {
        return http.post(`/buyer/pay/thirdparty/brazil/rechargeList`, data, { 'content-type': 'application/json' })
    },
    //获取聊天
    getChat(data) {
        return http.post(`/buyer/chat/getChat`, data, { 'content-type': 'application/json' })
    },
    //获取聊天详情
    getChatDetail(data) {
        return http.post(`/buyer/chat/getChatDetail`, data, { 'content-type': 'application/json' })
    },
    //发送聊天
    sendChat(data) {
        return http.post(`/buyer/chat/sendChat`, data, { 'content-type': 'application/json' })
    },

    //修改支付密码
    updateTransPassword(data) {
        return http.post(`/buyer/user/updateTransPassword`, data, { 'content-type': 'application/json' })
    },
    //首次设置支付密码
    setTransPassword(data) {
        return http.post(`/buyer/user/settingTransPassword`, data, { 'content-type': 'application/json' })
    },
    //根据店铺用户获取文件
    getFileByShopUserName(data) {
        return http.get(`/buyer/shopFile/getFileByShopUserName`, data);
    },
    //买家收藏店铺
    addCollectShop(data) {
        return http.post(`/buyer/collect/bind`, data, headers)
    },
    //买家取消收藏列表
    cancelCollectShop(data) {
        return http.post(`/buyer/collect/cancel`, data, headers)
    },
    //买家收藏列表
    collectShopList(data) {
        return http.post(`/buyer/collect/list`, data, headers)
    },
    //用户和店铺是否收藏绑定
    isCollectedShop(data) {
        return http.post(`/buyer/collect/isCollected`, data, headers)
    },
    //买家收藏商品
    addCollectProduct(data) {
        return http.post(`/buyer/products/collect/bind`, data, headers)
    },
    //买家取消收藏商品
    cancelCollectProduct(data) {
        return http.post(`/buyer/products/collect/cancel`, data, headers)
    },
    //买家商品收藏列表
    collectProductList(data) {
        return http.post(`/buyer/products/collect/list`, data, headers)
    },
    //用户和店铺是否收藏绑定
    isCollectedProduct(data) {
        return http.post(`/buyer/products/collect/isCollected`, data, headers)
    },

    //关于我们
    getAboutUs(data) {
        return http.get(`/buyer/logo/aboutUs`, data)
    },
    //获取公告
    noticeList(data) {
        return http.get(`/buyer/broadcast/list`, data)
    },

    //获取首页，广告等 banner 图片
    getBannerByName(data) {
        return http.get(`/buyer/banner/getBannerByName`, data)
    },
    //PC-获取聊天
    getPcChat(data) {
        return http.post(`/buyer/chat/getPcChat`, data, headers)
    },
    //价格专区
    homeQuerySellPrice(data) {
        return http.post(`/buyer/products/homeQuerySellPrice`, data, headers)
    },
    //限时专区
    homeQueryEndTimePrice(data) {
        return http.post(`/buyer/products/homeQueryEndTimePrice`, data, headers)
    },
    bindCollect(data) {
        return http.post(`/buyer/collect/bind`, data, { 'content-type': 'application/json' })
    },

    ispCollect(data) {
        return http.post(`/buyer/collect/isCollected`, data, { 'content-type': 'application/json' })
    },
    cancelCollect(data) {
        return http.post(`/buyer/collect/cancel`, data, { 'content-type': 'application/json' })
    },
    findHotShop(data) {
        return http.post(`/buyer/shop/findHotShop`, data, { 'content-type': 'application/json' })
    },
    //     /buyer/products/homeQueryActivityProducts
    homeQueryActivityProducts(data) {
        return http.post(`/buyer/products/homeQueryActivityProducts`, data, { 'content-type': 'application/json' })
    },
    queryProductsComments(data) {
        return http.post('/buyer/buy/products/queryProductsComments', data, headers)
    },
    //     /buyer/logo/getLogo
    getLogo(data) {
        return http.post('/buyer/logo/getLogo', data, headers)
    },
    // 获取店铺等级map
    getShopLevelMap(data) {
        return http.get('/buyer/shop/shopLevel', data, headers)
    },
    registerCodeIsEnable(data) {
        return http.get('/buyer/user/registerCodeIsEnable', data, headers)
    },

    invitationCode(data) {
        return http.get('/buyer/user/invitationCode', data, headers)
    },
    addUserWalletBank(data) {
        return http.post('/buyer/userWallet/addUserWalletBank', data, headers)
    },
    editUserWalletBank(data) {
        return http.post('/buyer/userWallet/editUserWalletBank', data, headers)
    },
    evaluateGoods(data) {
        return http.post('/buyer/buy/products/evaluateGoods', data, headers)
    },
    getWalletFreeze(data){
        return http.get('/buyer/user/getWalletFreeze',data,headers)
    },
}
